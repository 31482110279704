import Image from 'next/image'
import NextLink from 'next/link'

import RichText from '@cms/components/RichText'
import { ImageWrapper } from '@knauf-group/ct-designs/components/core/ImageWrapper'
import { VideoPlayer } from '@knauf-group/ct-designs/components/core/VideoPlayer'
import { WebLink } from '@knauf-group/ct-designs/components/core/WebLink'
import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/themeConfigs/constants'
import { RTLIconStyles } from '@knauf-group/ct-designs/utils/utils'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import type { TeaserWrapperProps } from './Teaser.types'
import { useTeaserProps } from './useTeaserProps'

/**
 * width and height determines the space reserved for the image on the page.
 * will be scaled to fit the available space/width while maintaining aspect ratio.
 */
const DEFAULT_IMAGE_WIDTH = 704
const DEFAULT_IMAGE_HEIGHT = 528

const Teaser: React.FC<TeaserWrapperProps> = ({ fields, dataCy = 'teaser' }) => {
  const {
    styles,
    image,
    video,
    mediaDescription,
    primaryRef,
    headline,
    richText,
    imageLoader,
    cookieSettingsTile,
    isScreenSm,
    imageSize,
  } = useTeaserProps(fields)
  return (
    <Container sx={WEB_CONTAINER_VERTICAL_SPACE} data-cy={dataCy}>
      <Grid sx={styles.container} container spacing={3}>
        <Grid item xs={12} md={6}>
          {/* If video is not provided from Contentful and image is null we should hide as it is optional */}
          {image?.url && (
            <Container disableGutters={isScreenSm} maxWidth={false}>
              <ImageWrapper
                image={image}
                width={DEFAULT_IMAGE_WIDTH}
                height={DEFAULT_IMAGE_HEIGHT}
                sx={styles.image}
                nextImageComponent={Image}
                loader={imageLoader}
                sizes={imageSize}
                dataCy={`${dataCy}-image`}
              />
            </Container>
          )}
          {video &&
            (video?.isVideoCookieAccepted ? (
              <Container disableGutters={isScreenSm} maxWidth={false}>
                <VideoPlayer
                  videoDetail={video}
                  light={
                    <ImageWrapper
                      image={video.thumbnail}
                      nextImageComponent={Image}
                      sx={styles.videoThumbnail}
                      loader={imageLoader}
                      sizes="(max-width: 1199px) 100vw, 50vw"
                    />
                  }
                  data-cy={`${dataCy}-video`}
                />
              </Container>
            ) : (
              cookieSettingsTile
            ))}
          {mediaDescription && (
            <Box display="flex" justifyContent={{ xs: 'center', lg: 'flex-end' }}>
              <Typography variant="caption" data-cy={`${dataCy}-caption`}>
                {mediaDescription}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={5} alignSelf="center">
          <Typography
            variant="h2"
            sx={{ mb: { xs: '16px', lg: '32px' } }}
            data-cy={`${dataCy}-headline`}
          >
            {headline}
          </Typography>
          {richText && <RichText richTextDocument={richText} />}
          {primaryRef?.label && primaryRef?.href && (
            <Box
              sx={{
                '& .MuiButton-root': {
                  p: 0,
                  mb: '40px',
                  '&:hover': { backgroundColor: 'transparent' },
                },
              }}
            >
              <WebLink
                dataCy={`${dataCy}-btn`}
                color="primary"
                isAnimated
                endIcon={<ArrowForward sx={RTLIconStyles} />}
                reference={primaryRef}
                nextLinkComponent={NextLink}
                prefetch={false}
              >
                {primaryRef?.label}
              </WebLink>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Teaser
